import React, { useRef } from 'react';
import useMeasure from 'react-use-measure';
import { proxy, useSnapshot } from 'valtio';

import { useSSRLayoutEffect } from '../../../app/components/hooks/useSSRLayoutEffect';
import { useInstance } from '../../hooks/useInstance';
import { ResizeObserver } from '../../utils/ResizeObserver';
import { ArrowNext, ArrowPrev } from '../Game/GameCenter/Utilities';

type SimpleCarouselControlState = {
  // this is a scroll offset, not an index.
  currentOffset: number;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
};

class SimpleCarouselControl {
  state;

  constructor(
    private cardWidth: number = 0,
    private gapWidth: number = 0,
    private numCards: number = 0,
    private preferScrollBy: number = 2,
    private fillEnd: boolean = false,
    private containerWidth: number = 0,
    private scrollBy: number = 1
  ) {
    this.state = proxy<SimpleCarouselControlState>({
      currentOffset: 0,
      prevDisabled: true,
      nextDisabled: true,
    });
  }

  get totalRowWidth(): number {
    return this.cardWidth * this.numCards + this.gapWidth * (this.numCards - 1);
  }

  resetScroll(): void {
    this.state.currentOffset = 0;
    this.recompute();
  }

  prev(): void {
    // the minimum limit of `currentOffset` is 0, meaning the first card in the row is flush with the left side of the
    // containing div.
    this.state.currentOffset = Math.max(
      this.state.currentOffset -
        (this.cardWidth + this.gapWidth) * this.scrollBy,
      0
    );
    this.recompute();
  }

  next(): void {
    // the maximum limit of `currentOffset` is the total width of the row minus a single card, meaning the last card
    // in the row is flush with the left side of the containing div.
    this.state.currentOffset = Math.min(
      this.state.currentOffset +
        (this.cardWidth + this.gapWidth) * this.scrollBy,
      this.fillEnd
        ? this.totalRowWidth - this.containerWidth
        : this.totalRowWidth - this.cardWidth
    );

    this.recompute();
  }

  resize(
    cardWidth: number,
    gapWidth: number,
    containerWidth: number,
    numCards: number
  ): void {
    this.cardWidth = cardWidth;
    this.gapWidth = gapWidth;
    this.containerWidth = containerWidth;
    this.numCards = numCards;
    this.recompute();
  }

  private recompute() {
    if (this.containerWidth === 0) return;

    const notVisible =
      this.totalRowWidth - (this.state.currentOffset + this.containerWidth);
    const numCardsVisible =
      this.containerWidth / (this.cardWidth + this.gapWidth);

    this.scrollBy = Math.max(1, Math.min(numCardsVisible, this.preferScrollBy));
    this.state.prevDisabled = this.state.currentOffset === 0;
    this.state.nextDisabled = notVisible <= 0;
  }
}

export function useSimpleCarouselControl(): SimpleCarouselControl {
  return useInstance(() => new SimpleCarouselControl());
}

type RenderArrow = (props: {
  onClick: () => void;
  disabled?: boolean;
}) => React.ReactNode | null;

export function SimpleCarousel(props: {
  cardWidth: number;
  gapWidth: number;
  preferScrollBy?: number;
  fillEnd?: boolean;
  children: React.ReactNode[];
  control?: SimpleCarouselControl;
  renderPrevArrow?: RenderArrow;
  renderNextArrow?: RenderArrow;
}): JSX.Element {
  const { cardWidth, gapWidth, preferScrollBy, fillEnd, children } = props;

  const control = useRef<SimpleCarouselControl>(
    props.control ??
      new SimpleCarouselControl(
        cardWidth,
        gapWidth,
        children.length,
        preferScrollBy,
        fillEnd
      )
  );
  const state = useSnapshot(control.current.state);

  const [ref, bounds] = useMeasure({
    debounce: 100,
    polyfill: ResizeObserver,
  });

  useSSRLayoutEffect(() => {
    control.current.resize(cardWidth, gapWidth, bounds.width, children.length);
  }, [bounds.width, children.length, cardWidth, gapWidth]);

  return (
    <div className='relative w-full'>
      {props.renderPrevArrow?.({
        onClick: () => control.current.prev(),
        disabled: state.prevDisabled,
      }) ?? (
        <button
          type='button'
          className='absolute left-0 disabled:hidden text-white focus:outline-none ring-0'
          style={{ top: bounds.height / 2 }}
          onClick={() => control.current.prev()}
          disabled={state.prevDisabled}
        >
          <ArrowPrev />
        </button>
      )}

      {props.renderNextArrow?.({
        onClick: () => control.current.next(),
        disabled: state.nextDisabled,
      }) ?? (
        <button
          type='button'
          className='absolute right-0 disabled:hidden text-white focus:outline-none ring-0'
          style={{ top: bounds.height / 2 }}
          onClick={() => control.current.next()}
          disabled={state.nextDisabled}
        >
          <ArrowNext />
        </button>
      )}

      <div ref={ref} className='w-full overflow-hidden'>
        <div
          className='inline-flex transition-transform ease-in-out duration-200'
          style={{
            transform: `translateX(-${state.currentOffset}px)`,
            gap: props.gapWidth,
          }}
        >
          {props.children.map((c, i) => (
            <div
              key={`simple-carousel-card-${i}`}
              className='flex-none'
              style={{
                width: props.cardWidth,
              }}
            >
              {c}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
