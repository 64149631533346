import { Link } from '@remix-run/react';
import { type HTMLAttributeAnchorTarget, useMemo } from 'react';

import {
  type DtoOnboardingTask,
  EnumsOnboardingTaskName,
  EnumsOnboardingTaskOperation,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { useOnboardingAnalytics } from '../../analytics/onboarding';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type Organization } from '../../types';
import { fromDTOGamePack } from '../../utils/api-dto';
import { err2s } from '../../utils/common';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { GamePackUtils } from '../Game/GamePack/utils';
import { GamePackCover } from '../Game/Utilities';
import { CalendarIcon } from '../icons/CalendarIcon';
import { CopyLinkIcon } from '../icons/CopyLinkIcon';
import { GameModeIcon } from '../icons/GameModeIcon';
import { HostIcon } from '../icons/HostIcon';
import { LockIcon } from '../icons/LockIcon';
import { MegaphoneIcon } from '../icons/MegaphoneIcon';
import { SlackIcon } from '../icons/SlackIcon';
import { TeamIcon } from '../icons/TeamIcon';
import {
  OrgSubscriptionUpgradeButton,
  useMyOrganization,
  useMyOrgId,
  useTriggerOrganizationDoNotUseSlackModal,
  useUpdateMyOrganization,
} from '../Organization';
import { SimpleCarousel } from '../SimpleCarousel/SimpleCarousel';
import { useOnboardingTasks } from './hooks';
import { OnboardingTaskUtils } from './utils';

function OnboardingTaskSkipModal(props: {
  task: DtoOnboardingTask;
  onCancel: () => void;
  onSkip: () => Promise<void>;
}) {
  const analytics = useOnboardingAnalytics();
  const orgId = useMyOrgId() || '';
  const {
    call: handleSkip,
    state: {
      state: { isRunning },
      error,
    },
  } = useLiveAsyncCall(async () => {
    await apiService.onboarding.operateTask(props.task.name, {
      orgId,
      operation: EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkClosed,
    });
    analytics.trackOnboardingTaskSkipped({
      name: props.task.name,
      displayName: props.task.displayName,
    });

    await props.onSkip();
  });

  return (
    <ModalWrapper
      containerClassName='w-86'
      borderStyle='gray'
      onClose={props.onCancel}
    >
      <div className='w-full px-7.5 py-5 text-center'>
        <header className='text-2xl font-medium'>
          Are you sure you want to skip this task?
        </header>

        <p className='mt-4 text-sms'>
          This will remove the task from your list.
        </p>

        <div className='mt-2 text-sms text-red-002 h-3'>{err2s(error)}</div>

        <div className='mt-1 flex justify-center items-center gap-2'>
          <button
            type='button'
            className='btn-secondary w-33 h-10 flex justify-center items-center'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-delete w-33 h-10 flex justify-center items-center'
            disabled={isRunning}
            onClick={handleSkip}
          >
            {isRunning ? 'Skipping' : 'Skip'}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

function OnboardingTaskSkipButton(props: {
  task: DtoOnboardingTask;
  refreshTasks: () => Promise<void>;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleClick = () => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <OnboardingTaskSkipModal
          task={props.task}
          onCancel={p.internalOnCancel}
          onSkip={async () => {
            await props.refreshTasks();
            p.internalOnConfirm();
          }}
        />
      ),
    });
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      disabled={props.task.isLocked}
      className='btn text-sms text-icon-gray underline'
    >
      Skip
    </button>
  );
}

function OnboardingTaskPrimaryButton(props: {
  task: DtoOnboardingTask;
  text: string;
  icon?: (props: { className?: string }) => React.ReactNode;
  to?: string;
  width?: `w-${number}`;
  onClick?: () => void;
  target?: HTMLAttributeAnchorTarget;
}) {
  const { task, icon, text, onClick, target } = props;
  const analytics = useOnboardingAnalytics();

  if (!task.hasPermission) {
    return (
      <OrgSubscriptionUpgradeButton
        disabled={task.isLocked}
        className='w-35 h-10 p-0.5'
      />
    );
  }

  if (task.isLocked) {
    return (
      <div
        className={`${
          props.width ?? 'w-35'
        } h-10 rounded-xl bg-light-gray flex justify-center items-center gap-2.5`}
      >
        <LockIcon className='w-5 h-5 fill-current' />
        {text}
      </div>
    );
  }

  return (
    <Link
      target={target}
      to={props.to ?? OnboardingTaskUtils.GetLink(props.task)}
      onClick={() => {
        analytics.trackOnboardingTaskCardClicked({
          name: task.name,
          displayName: task.displayName,
          area: 'Primary Button',
        });
        if (onClick) onClick();
      }}
      className={`btn-primary ${
        props.width ?? 'w-35'
      } h-10 flex justify-center items-center gap-2.5`}
    >
      {icon &&
        icon({
          className: 'w-5 h-5 fill-current',
        })}
      {text}
    </Link>
  );
}

function OnboardingTaskCardLayout(props: {
  icon: (props: { className?: string }) => React.ReactNode;
  task: DtoOnboardingTask;
  description: string;
  primaryButton: React.ReactNode;
  secondaryButton?: React.ReactNode;
  refreshTasks: () => Promise<void>;
  accessory?: React.ReactNode;
}) {
  const {
    icon,
    task,
    description,
    primaryButton,
    secondaryButton,
    refreshTasks,
    accessory,
  } = props;

  return (
    <div
      className={`relative w-full h-45 flex-shrink-0 
        bg-secondary border border-secondary rounded-2.5xl 
        p-5 pr-4 flex flex-col justify-between
        ${task.isLocked ? 'opacity-40' : ''}
    `}
      title={task.isLocked ? task.lockedReason : ''}
    >
      <h3 className='text-base text-bold flex items-center gap-2.5'>
        {icon({
          className: 'w-7.5 h-7.5 fill-current',
        })}
        {task.displayName}
      </h3>
      <p className='text-sms h-9 line-clamp-2'>{description}</p>
      <div className='flex items-center gap-5'>
        {primaryButton}

        {secondaryButton}
      </div>

      <div className='absolute right-2 bottom-2'>
        {accessory || (
          <OnboardingTaskSkipButton task={task} refreshTasks={refreshTasks} />
        )}
      </div>
    </div>
  );
}

function DoNotUseSlackButton() {
  const organization = useMyOrganization();
  const updateMyOrganization = useUpdateMyOrganization();
  const triggerDoNotUseSlackModal = useTriggerOrganizationDoNotUseSlackModal();

  if (!organization) return null;

  const handleClick = () => {
    triggerDoNotUseSlackModal({
      organization,
      onConfirmed: async () => {
        if (!organization) return;

        updateMyOrganization({
          ...organization,
          usesSlack: false,
        });

        await apiService.onboarding.operateTask(
          EnumsOnboardingTaskName.OnboardingTaskNameConnectToSlack,
          {
            orgId: organization.id,
            operation:
              EnumsOnboardingTaskOperation.OnboardingTaskOperationMarkDone,
          }
        );
      },
    });
  };

  return (
    <button
      type='button'
      className='btn text-3xs text-icon-gray'
      onClick={handleClick}
    >
      What if we don’t use Slack?
    </button>
  );
}

function OnboardingTaskCard(props: {
  organization: Organization;
  task: DtoOnboardingTask;
  refreshTasks: () => Promise<void>;
}) {
  switch (props.task.name) {
    case EnumsOnboardingTaskName.OnboardingTaskNameScheduleCall:
      return (
        <OnboardingTaskCardLayout
          icon={CalendarIcon}
          task={props.task}
          description='Your account manager will walk you through the set up and answer any questions you may have.'
          primaryButton={
            <OnboardingTaskPrimaryButton task={props.task} text='Schedule' />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameConnectToSlack:
      return (
        <OnboardingTaskCardLayout
          icon={CopyLinkIcon}
          task={props.task}
          description='Organizations that install our app for Slack see 45% higher engagement.'
          primaryButton={
            <OnboardingTaskPrimaryButton
              task={props.task}
              text='Add to Slack'
              icon={SlackIcon}
            />
          }
          secondaryButton={<DoNotUseSlackButton />}
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameAddTeam:
      return (
        <OnboardingTaskCardLayout
          icon={TeamIcon}
          task={props.task}
          description='Import your team members in just a few easy steps so they can enjoy all that Luna Park has to offer.'
          primaryButton={
            <OnboardingTaskPrimaryButton
              task={props.task}
              text='Add Team'
              icon={TeamIcon}
            />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameSetUpPrograms:
      return (
        <OnboardingTaskCardLayout
          icon={SlackIcon}
          task={props.task}
          description='Drive meaningful interactions with Intros, Water Coolers, Recognition, and Celebrations.'
          primaryButton={
            <OnboardingTaskPrimaryButton task={props.task} text='Continue' />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameNotifyTeam:
      return (
        <OnboardingTaskCardLayout
          icon={MegaphoneIcon}
          task={props.task}
          description='Share the news with your team and tell them a bit about Luna Park!'
          primaryButton={
            <OnboardingTaskPrimaryButton
              task={props.task}
              text='Announce'
              icon={CopyLinkIcon}
            />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameSubmitDates:
      return (
        <OnboardingTaskCardLayout
          icon={CalendarIcon}
          task={props.task}
          description='Add your employees birthdays and anniversaries so that they feel remembered and appreciated!'
          primaryButton={
            <OnboardingTaskPrimaryButton task={props.task} text='Submit' />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameScheduleOndGame:
      return (
        <OnboardingTaskCardLayout
          icon={GameModeIcon}
          task={props.task}
          description='Schedule a time to play a Luna Park On Demand game with your colleagues.'
          primaryButton={
            <OnboardingTaskPrimaryButton task={props.task} text='Schedule' />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameBookLiveShow:
      return (
        <OnboardingTaskCardLayout
          icon={HostIcon}
          task={props.task}
          description='Take your next team building event to another level. Book a live hosted event your team will love!'
          primaryButton={
            <OnboardingTaskPrimaryButton task={props.task} text='Schedule' />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNameSchedulePurchasedOndGame:
      return (
        <OnboardingTaskCardLayout
          icon={() => {
            return (
              <div className='w-22.5'>
                <GamePackCover
                  pack={fromDTOGamePack(props.task.data?.gamePack)}
                  preferredFormatVersion={MediaFormatVersion.SM}
                />
              </div>
            );
          }}
          task={props.task}
          description={`You’ve recently purchased “${props.task.data?.gamePack?.name}”. Schedule the game now!`}
          primaryButton={
            <OnboardingTaskPrimaryButton
              task={props.task}
              text='Schedule'
              to={
                props.task.data?.destination ??
                `/events/create?game-pack-id=${props.task.data?.gamePack?.id}`
              }
            />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    case EnumsOnboardingTaskName.OnboardingTaskNamePendingPurchaseOndGame:
      if (!props.task.data?.gamePack) return null;

      return (
        <OnboardingTaskCardLayout
          icon={() => {
            return (
              <div className='w-22.5'>
                <GamePackCover
                  pack={fromDTOGamePack(props.task.data?.gamePack)}
                  preferredFormatVersion={MediaFormatVersion.SM}
                />
              </div>
            );
          }}
          task={props.task}
          description={`Your purchase of “${props.task.data.gamePack.name}” is incomplete.`}
          primaryButton={
            <OnboardingTaskPrimaryButton
              task={props.task}
              text='Continue Purchase'
              to={
                props.task.data?.destination ||
                GamePackUtils.GetOneTimePurchaseUrl(
                  fromDTOGamePack(props.task.data.gamePack)
                )
              }
              width='w-40'
            />
          }
          refreshTasks={props.refreshTasks}
        />
      );
    default:
      return null;
  }
}

export function OnboardingTasksRow(props: {
  organization: Organization;
}): JSX.Element | null {
  const { organization } = props;

  const { data: tasks, mutate } = useOnboardingTasks(organization.id);

  const showTasks = useMemo(() => {
    return (
      tasks?.filter((t) => t.isRelevant && !t.isCompleted && !t.isSkipped) || []
    );
  }, [tasks]);

  if (showTasks.length === 0) return null;
  return (
    <div className='bg-onboarding-tasks-row w-full px-12.5 py-7.5 text-white'>
      <header className='w-full'>
        <div className='text-3.5xl font-bold'>Let's Get Set Up</div>
        <div className='mt-1 text-sms'>Visible to Admins</div>
      </header>

      <div className='mt-4 w-full'>
        <SimpleCarousel gapWidth={10} cardWidth={360}>
          {showTasks
            .map((task) => (
              <OnboardingTaskCard
                key={task.name}
                organization={organization}
                task={task}
                refreshTasks={async () => {
                  await mutate();
                }}
              />
            ))
            .filter(Boolean)}
        </SimpleCarousel>
      </div>
    </div>
  );
}
